import Vue from 'vue'

// axios
import axios from 'axios'

// let site = window.location.origin
// if (site === 'http://localhost:8080') {
//   site = 'https://admin.pgsoft-th.cc'
// }
// const obj = {
//   site_admin: site,
//   // site_admin: 'http://pgsoft-th.ak11bet.com',
// }
// let endpoint = '';
// if(site.includes('bkbet')) {
//   endpoint = 'https://adminapi.bkbet365.cc/api';
// } else if(site.includes('pgsoft')) {
//   endpoint = 'https://api.pgbackend-th.com/api';
// } else {
//   endpoint = 'http://localhost:4040/api'
// }
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // baseURL: endpoint,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  // headers: {
  //   'Content-Type': 'multipart/form-data',
  // },
})
// console.log(obj);
const currentOrigin = window.origin
let agApi = ''
if (currentOrigin.includes('pgback')) {
  agApi = 'https://ag.ak47-ag1ct.com'
} else if (currentOrigin.includes('bk')) {
  agApi = 'https://agbk.ak47-ag1ct.com'
} else if (currentOrigin.includes('shibuya')) {
  agApi = 'https://shibuya.ak47-ag1ct.com'
} else if (currentOrigin.includes('narita')) {
  agApi = 'https://agnari.ak47-ag1ct.com'
} else if (currentOrigin.includes('friend')) {
  agApi = 'https://aguat.ak47-ag1ct.com'
} else {
  agApi = 'https://aguat.ak47-ag1ct.com'
}
axiosIns.interceptors.request.use(
  async config => {
    await axios
      .get(
        `${agApi}/api/agent/getEndpoint${
          window.origin.includes('localhost') ? '?agent_id=5' : ''
        }`,
      )
      // eslint-disable-next-line no-unused-vars
      .then(response => {
        localStorage.setItem('endpoint', response.data.endpoint)
        // response.data.endpoint
        // eslint-disable-next-line no-param-reassign
        // config.baseURL = response.data.endpoint
        config.baseURL = window.origin.includes('localhost')
          ? 'http://localhost:4040/api'
          : response.data.endpoint
      })
      .catch(error => console.log(error))
    // console.log(config.headers.Authorization)
    return config
  },
  error => Promise.reject(error),
)
Vue.prototype.$http = axiosIns

export default axiosIns
